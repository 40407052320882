import moment from "moment";
import swal from "sweetalert";
import * as requestFromServer from "./MasterCrud";

export const fetchPriceBundle = (setPriceBundle) => {
  return requestFromServer
    .fetchPriceBundle()
    .then((response) => {
      if (response.data.statusCode === "200") {
        const data = response.data.data.map(({ id, name }) => ({
          value: id,
          label: name,
        })
        );
        setPriceBundle([{ value: "All", label: "All" }].concat(data))
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Price Bundle";
    });
};
export const fetchMasters = (setData, setLoading) => {
  setLoading(true);
  return requestFromServer
    .getAllmasters()
    .then((response) => {
      if (response.data.statusCode === "200") {
        setData(response.data.data.sort((a, b) => b.id - a.id));
        setLoading(false);
      } else {
        setLoading(false);
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find masters";
      setLoading(false);
    });
};

export const fetchMaster = (id, sno, setActionsLoading, setMasterForEdit) => {
  if (!id) {
    setActionsLoading(false);
    setMasterForEdit(undefined);
    return;
  }
  setActionsLoading(true);
  return requestFromServer
    .getmasterById(id)
    .then((response) => {
      const master = response.data.data;

      setMasterForEdit({
        ...master,
        sno: sno,
        validityfrom: moment(master?.validityfrom).format("MM/DD/YYYY"),
        validityto: moment(master?.validityto).format("MM/DD/YYYY"),
      });
      setActionsLoading(false);
    })
    .catch((error) => {
      error.clientMessage = "Can't find master";
      setActionsLoading(false);
    });
};

export const fetchMetric = (setMetric) => {
  return requestFromServer
    .fetchMetricName()
    .then((response) => {
      if (response.data.statusCode === "200") {
        setMetric(
          response.data.data.map(({ id, name }) => ({
            mM_Id: id,
            mM_Name: name,
            value: id,
            label: name,
          }))
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Metrics";
    });
};

export const fetchCountry = (setCountry) => {
  return requestFromServer
    .fetchCountry()
    .then((response) => {
      if (response.data.statusCode === "200") {
        const data = response.data.data.map(({ id, name }) => ({
          value: id,
          label: name,
        })
        );
        setCountry([{ value: "All", label: "All" }].concat(data))
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Countries";
    });
};
export const fetchRegionCountry = (setRegionCountry) => {
  return requestFromServer
    .fetchRegionCountry()
    .then((response) => {
      if (response.data.statusCode === "200") {
        const data = response.data.data.map(({ id, name }) => ({
          value: id,
          label: name,
        })
        );
        setRegionCountry([{ value: "All", label: "All" }].concat(data))
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Countries";
    });
};

export const fetchCompanyAge = (setCompanyAge) => {
  return requestFromServer
    .fetchCompanyAge()
    .then((response) => {
      if (response.data.statusCode === "200") {

        const data = response.data.data.map(({ id, description }) => ({
          value: id,
          label: description,
        })
        );
        setCompanyAge([{ value: "All", label: "All" }].concat(data))
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Company Age";
    });
};
export const fetchCompanySize = (setCompanySize) => {
  return requestFromServer
    .fetchCompanySize()
    .then((response) => {
      if (response.data.statusCode === "200") {
        const data = response.data.data.map(({ id, description }) => ({
          value: id,
          label: description,
        })
        );
        setCompanySize([{ value: "All", label: "All" }].concat(data))
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Company Size";
    });
};


export const fetchIndustryType = (setIndustryType) => {
  return requestFromServer
    .fetchIndustryType()
    .then((response) => {
      if (response.data.statusCode === "200") {
        const data = response.data.data.map(({ id, name }) => ({
          value: id,
          label: name,
        })
        );
        setIndustryType([{ value: "All", label: "All" }].concat(data))
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Industry Type";
    });
};

export const fetchIndustryClusters = (setIndustryClusters) => {
  return requestFromServer
    .fetchIndustryCluster()
    .then((response) => {
      if (response.data.statusCode === "200") {
        const data = response.data.data.map(({ id, name }) => ({
          value: id,
          label: name,
        })
        );
        setIndustryClusters([{ value: "All", label: "All" }].concat(data))
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Industry Clusters";
    });
};
export const deleteMaster = (id, setMasterLoading, onHide, setLoading) => {
  setLoading(true);
  return requestFromServer
    .deletemaster(id)
    .then((response) => {
      if (response.data.statusCode === "200") {
        setMasterLoading(true);
        onHide();
        setLoading(false);
        return;
      }
      swal("warning", `${response.data.message} "Database Message:"${response.data.data.databaseMessage}`, "warning");
      setLoading(false);
    })
    .catch((error) => {
      error.clientMessage = "Can't create master";
      setLoading(false);
    });
};
export const createMaster = (
  masterForCreation,
  setLoading,
  setMasterData,
  setMasterLoading,
  onHide
) => {
  setLoading(true);
  return requestFromServer
    .createmaster(masterForCreation)
    .then((response) => {
      if (response.data.statusCode === "200") {
        setMasterLoading(true);
        onHide();
        setLoading(false);
        return;
      }
      swal("warning", response.data.message, "warning");
      setLoading(false);
    })
    .catch((error) => {
      error.clientMessage = "Can't create master";
      setLoading(false);
    });
};
export const updateMaster = (
  master,
  setLoading,
  setMasterData,
  setMasterLoading,
  onHide
) => {
  setLoading(true);
  return requestFromServer
    .updatemaster(master)
    .then((response) => {
      if (response.data.statusCode === "200") {
        setMasterLoading(true);
        onHide();
        setLoading(false);
        return;
      }
      swal("warning", response.data.message, "warning");
      setLoading(false);
    })
    .catch((error) => {
      error.clientMessage = "Can't create master";
      setLoading(false);
    });
};


