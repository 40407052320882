import objectPath from "object-path";
import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useRoutesContext } from "../../../../app/RoutesContext";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { HelpToggler } from "../extras/dropdowns/HelpToggler";
import { QuickUserToggler } from "../extras/QuiclUserToggler";

export function Topbar() {
  const uiService = useHtmlClassService();
  const RoutesContext = useRoutesContext();
console.log("RoutesContext",RoutesContext)
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  

  return (
    <div className="topbar align-items-center">
      
      {RoutesContext.page !== null ? (
        <>
          {RoutesContext.page !== "video" ? (
            <NavLink
              to="/helpandsupport/main"
              onClick={() => {
                RoutesContext.setPage("video");
              }}
            >
              <div className="topbar-item">
                <div
                  className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                  id="kt_quick_user_toggle"
                >
                  <>
                    <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                      Video Tutorial
                    </span>
                  </>
                </div>
              </div>
            </NavLink>
          ) : null}
          {RoutesContext.page !== "faq" ? (
            <NavLink
              to="/helpandsupport/main"
              onClick={() => {
                RoutesContext.setPage("faq");
              }}
            >
              <div className="topbar-item">
                <div
                  className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                  id="kt_quick_user_toggle"
                >
                  <>
                    <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                      FAQs
                    </span>
                  </>
                </div>
              </div>
            </NavLink>
          ) : null}
          {RoutesContext.page !== "help" ? (
            <NavLink
              to="/helpandsupport/main"
              onClick={() => {
                RoutesContext.setPage("help");
              }}
            >
              <div className="topbar-item">
                <div
                  className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                  id="kt_quick_user_toggle"
                >
                  <>
                    <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                      Help
                    </span>
                  </>
                </div>
              </div>
            </NavLink>
          ) : null}
        </>
      ) : (
        <HelpToggler />
      )}

      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
  );
}
