/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useRoutesContext } from "../../../../../app/RoutesContext";
import AppAxios, { Headers } from "../../../../../axios";
import { checkIsActive } from "../../../../_helpers";

export function FAQAsideMenuList({ layoutProps }) {
  const [menuCategory, setMenuCategory] = useState([]);
  const RoutesContext = useRoutesContext();

  useEffect(() => {
    setMenuCategory([]);
    AppAxios.get("/UserMenu", {
      headers: Headers(),
    })
      .then((response) => {
        if (response.data.statusCode === "200") {
          setMenuCategory(response.data.data);
        } else {
          setMenuCategory(null);
        }
      })
      .catch((error) => {});
  }, []);

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul
        className={`menu-nav data-auto-scroll="true" ${layoutProps.ulClasses}`}
      >
        {menuCategory && menuCategory.length > 0
          ? menuCategory.map((item) => (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/google-material",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to={RoutesContext.page ==='faq' ? `/helpandsupport/faq/${item.id}` :`/helpandsupport/video/${item.id}`}
                >
                  {/* <span className="svg-icon menu-icon">
                    {IconToRender[item.name]}
                  </span> */}
                  <span className="menu-text">{item.name}</span>
                  <i className="menu-arrow" />
                </NavLink>
              </li>
            ))
          : null}
      </ul>
    </>
  );
}
