/* eslint-disable react-hooks/rules-of-hooks */
import { useFormik } from "formik";
import React, {useEffect} from "react";
import { Link, useLocation } from "react-router-dom";
import swal from "sweetalert";
import * as Yup from "yup";
import { catchOtherStatusErrors } from "../../CommonModules/generic";
import { changepassword } from "../config/authCrud";

export default function ChangePassword(props) {
  const search = useLocation().search;
  const email = new URLSearchParams(search).get("email");
  const token = new URLSearchParams(search).get("token");
  const initialValues = {
    token: token,
    email: email,
    password: "",
    confirmPassword: "",
  };
  useEffect(() => {
    if(!email&& !token){
       window.location.href='/auth/forgot-password';
    }
  },[])
  const ChangePasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .required("Email Required!"),
    password: Yup.string()
      .min(8, "Password is too short - should be 8 chars minimum.")
      .max(50, "Password is too long - should be less then 50 chars minimum.")
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@$#!%*?&]{8,}$/,
        `Passwords must be at least 8 characters.\n
       Passwords must have at least one non alphanumeric character.\n
       Passwords must have at least one digit ('0'-'9').\n
       Passwords must have at least one uppercase ('A'-'Z').
      `
      )
      .required("Password Required!"),
    confirmPassword: Yup.string()
      .min(8, "Password is too short - should be 8 chars minimum.")
      .max(50, "Password is too long - should be less then 50 chars minimum.")
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$#!%*?&]{8,}$/,
        `Passwords must be at least 8 characters.\n
       Passwords must have at least one non alphanumeric character.\n
       Passwords must have at least one digit ('0'-'9').\n
       Passwords must have at least one uppercase ('A'-'Z').
      `
      )
      .required("Confirm Password Required!")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });


  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ChangePasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      let state = {
        email: values.email,
        newPassword: values.password,
        confirmPassword: values.confirmPassword,
        token: token,
      };

      changepassword(state)
        .then((res) => {
          console.log(res);
          if (res.data.statusCode === "200") {
            setSubmitting(false);
            // props.history.push('/auth/login');
            props.history.replace({ pathname: "/auth/login" });
            swal("Success", res.data.message, "success");
            //props.register('accessToken');
            setSubmitting(false);
          } else {
            setSubmitting(false);
            setStatus(res.data.message);
          }
       
          setSubmitting(false);
        })
        .catch((err) => {
          setSubmitting(false);
          setStatus(catchOtherStatusErrors(err));
        });
    },
  });
  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">Change Password</h3>
        <p className="text-muted font-weight-bold">
          Enter your details to change your password
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Confirm Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "confirmPassword"
            )}`}
            name="confirmPassword"
            {...formik.getFieldProps("confirmPassword")}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.confirmPassword}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {formik.isSubmitting  && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}
