/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Dashboard } from "../../_metronic/_partials";
import { useRoutesContext } from "../RoutesContext";

export function DashboardPage() {
  const RoutesContext = useRoutesContext();
  useEffect(() => {
    RoutesContext.setPage(null);
  }, []);

  return <Dashboard />;
}
