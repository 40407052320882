import React, { useMemo } from "react";
import { useRoutesContext } from "../../../../../app/RoutesContext";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { AsideMenuList } from "./AsideMenuList";
import { FAQAsideMenuList } from "./FAQAsideMenuList";
import HelpAsideMenuList from "./HelpAsideMenuList";

export function AsideMenu({ disableScroll }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true),
    };
  }, [uiService]);
  const RoutesContext = useRoutesContext();
  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        {RoutesContext.page !== null ? (
          RoutesContext.page === "help" ? (
            <HelpAsideMenuList layoutProps={layoutProps} />
          ) : (
            <FAQAsideMenuList layoutProps={layoutProps} />
          )
        ) : (
          <AsideMenuList layoutProps={layoutProps} />
        )}
      </div>
      {/* end::Menu Container */}
    </>
  );
}
