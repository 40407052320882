/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import BarChartIcon from "@material-ui/icons/BarChart";
import BusinessIcon from "@material-ui/icons/Business";
import PermDataSettingIcon from "@material-ui/icons/PermDataSetting";
import SettingsIcon from "@material-ui/icons/Settings";
import TimelineIcon from "@material-ui/icons/Timeline";
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import AppAxios, { Headers } from "../../../../../axios";
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";
export function AsideMenuList({ layoutProps }) {
  const [menuCategory, setMenuCategory] = useState([]);
  const { user } = useSelector((state) => state.auth);
  console.log("userrr",user)
  useEffect(() => {
    AppAxios.get("/UserMenuCategory", { headers: Headers() })
      .then((response) => {
        if (response.data.statusCode === "200") {
          setMenuCategory(response.data.data);
        }
      })
      .catch((error) => {});
  }, []);
  let { userMenuPermissions } = user;
  let admin_menus = [];
  for (let q = 0; q < menuCategory.length; q++) {
    let menus = [];
    for (let f = 0; f < userMenuPermissions.length; f++) {
      if (menuCategory[q].name === userMenuPermissions[f].mC_Name) {
        menus.push({
          id: userMenuPermissions[f].id,
          name: userMenuPermissions[f].name,
          alias: userMenuPermissions[f].alias,
          viewPermission: userMenuPermissions[f].viewPermission,
        });
      }
    }
    let viewPermission = menus.some((item) => item.viewPermission === true);
    if (viewPermission) {
      admin_menus.push({
        name: menuCategory[q].name,
        menus: menus.filter((item) => item.viewPermission === true),
      });
    }
  }
  //let {viewPermission} = userMenuPermissions?.find(item => item.name === 'GeneralConfiguration');
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  const IconToRender = {
    "General Configuration": <SettingsIcon />,
    Industry: <BusinessIcon />,
    "Vsigns Configuration": <TimelineIcon />,
    "Benchmark Configuration": <BarChartIcon />,
    "Primary Data Configuration": <PermDataSettingIcon />,
    "Price Plan Configuration": <AttachMoneyIcon />,
    "User Management": <AccountCircleIcon />,
  };
  useEffect(() => {
    document.getElementById('HEADER').scrollIntoView();
  },[])  
  return (
    <div>
      {/* begin::Menu Nav */}
      <ul
        id="HEADER"
        className={`menu-nav data-auto-scroll="true" ${layoutProps.ulClasses}`}
      >
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/* General Configuration */}
        {admin_menus && admin_menus.length > 0
          ? admin_menus.reverse().map((item) => (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/google-material",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/google-material"
                >
                  <span className="svg-icon menu-icon">
                    {IconToRender[item.name]}
                  </span>
                  <span className="menu-text">{item.name}</span>
                  <i className="menu-arrow" />
                </NavLink>
                <div className="menu-submenu ">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    {console.log("itemsss",item)}
                    {item.menus.map((item) => (
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                          `${item.alias}`,
                          true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to={item.alias}
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">{item.name}</span>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            ))
          : null}

        <li className="menu-section ">
          <h4 className="menu-text">Reports</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* MIS Reports */}
        {/*begin::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </div>
  );
}
