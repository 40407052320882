/* eslint-disable react-hooks/exhaustive-deps */
import { useField, useFormikContext } from "formik";
import React , {useEffect} from "react";
import DatePicker from "react-datepicker";
const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function DatePickerField({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  useEffect(()=>{
    if(field.value==='' && !props.label.includes('To')){
      setFieldValue(field.name,new Date())
    }
  },[field.value])
  
  return (
    <>
      {props.label && <label>{props.label}</label>}
      <br />
      <DatePicker
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        autoComplete="off"
        style={{ width: "100%" }}
        {...field}
        {...props}
        value={(field.value && new Date(field.value))}
        selected={(field.value && new Date(field.value))}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
      />
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">
          {/* {errors['date'].toString()} */}
          {props.label.includes("*")
            ? props.label.split("*")[0]
            : props.label}{" "}
            is Required!
        </div>
      ) : (
        <div className="feedback">
          Please enter <b>{props.label}</b> in 'mm/dd/yyyy' format
        </div>
      )}
    </>
  );
}
