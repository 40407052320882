import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { DashboardPage } from "./pages/DashboardPage";
import { MyPage } from "./pages/MyPage";

const UserManagementPage = lazy(() =>
  import("./modules/UserManagement/pages/UserManagementPage")
);
const BenchmarkConfiguration = lazy(() =>
  import("./modules/BenchmarkConfiguration/pages/BenchmarkConfigurationPage")
);

const VitalSignConfigurationPage = lazy(() =>
  import("./modules/VsignsConfiguration/pages/VsignConfigurationPage")
);

const IndustryPage = lazy(() =>
  import("./modules/Industry/pages/IndustryPage")
);

const PricePlanConfigurationPage = lazy(() =>
  import("./modules/PricePlanConfiguration/pages/PricePlanConfigurationPage")
);

const GeneralConfigurationPage = lazy(() =>
  import("./modules/GeneralConfiguration/pages/GeneralConfigurationPage")
);

const PrimaryDataConfigurationPage = lazy(() =>
  import(
    "./modules/PrimaryDataConfiguration/pages/PrimaryDataConfigurationPage"
  )
);

const HelpandSupport = lazy(() =>
  import("./modules/HelpandSupport/HelpandSupport")
);

export default function BasePage({ setPage }) {
  // useEffect(() => {
  //   
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/user-management" component={UserManagementPage} />
        <Route
          path="/vsigns-configuration"
          component={VitalSignConfigurationPage}
        />

        <Route path="/industry" component={IndustryPage} />

        <Route path="/helpandsupport" component={HelpandSupport} />
        <Route
          path="/benchmark-configuration"
          component={BenchmarkConfiguration}
        />
        <Route path="/price-plan" component={PricePlanConfigurationPage} />
        <Route
          path="/general-configuration"
          component={GeneralConfigurationPage}
        />
        <Route path="/primary-data" component={PrimaryDataConfigurationPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
