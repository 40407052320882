import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";


export const actionTypes = {
  DescriptionLoaded: "[Load Description] Help API"
};

const initialState = {
  help: [],
};

export const reducer = persistReducer(
  { storage, key: "help" },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.DescriptionLoaded: {
        const description = action.payload;
        return  {...state, help:description};
      }
      default:
        return state;
    }
  }
);

export const actions = {
  requestDescription: data => ({ type: actionTypes.DescriptionLoaded, payload: data }),
};

export function* saga() {
  yield takeLatest(actionTypes.DescriptionLoaded, function* loginSaga() {
    yield put(actions.requestDescription());
  });
}
