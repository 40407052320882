import axios from "axios";
import store from "./redux/store";

//Live;
const AppAxios = axios.create({
    baseURL: process.env.REACT_APP_END_POINT,
    //baseURL: "https://vsigns-api-prod.azurewebsites.net/api/v1",
    // baseURL:"http://192.168.0.19:84/api/v1"
});

export const Headers = (_id) => {
  const { authToken } = store.getState().auth;
  if (!_id) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: authToken,
    };
  }
  return {
    _id: _id,
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: authToken,
  };
};

export default AppAxios;
