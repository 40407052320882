import { HelpOutline } from "@material-ui/icons";
import React from "react";
import PageHelpTipAside from "../../../_metronic/layout/components/aside/PageHelpTipAside";

export default function FAQDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const { menuData } = props;
  console.log("menuData",menuData)
  const handleClick = (title) => {
    setTitle(title);
    setOpen(!open);
  };
  console.log("menuData",menuData)
  return (
    <div>
      <button
        className="btn btn-light btn-sm font-weight-bold mr-2"
        onClick={() => handleClick("Page Tips")}
      >
        <span
          className="text-muted font-weight-bold mr-2"
          id="kt_dashboard_daterangepicker_title"
        >
          <i class="far fa-lightbulb"></i>
        </span>
        <span
          className="text-primary font-weight-bold"
          id="kt_dashboard_daterangepicker_date"
        >
          Page Tips
        </span>
      </button>
      <button
        className="btn btn-light btn-sm font-weight-bold ml-2"
        onClick={() => handleClick("Page Help")}
      >
        <span
          className="text-muted font-weight-bold mr-2"
          id="kt_dashboard_daterangepicker_title"
        >
          <HelpOutline />
        </span>
      </button>
      <PageHelpTipAside
        open={open}
        setOpen={setOpen}
        // menuData={menuData}
        title={title}
        {...props}
      />
    </div>
  );
}
