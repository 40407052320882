//import axios from "axios";

import AppAxios, { Headers } from "../../../../axios";

export const MASTER_URL = "/BenchmarkAdjFactor";
export const MetricName_URL = "/MetricMaster";
export const CompanySize_URL = "/LovServices/GetCompanySizeLov";
export const CompanyAge_URL = "/CompanyAge";
export const IndustryCluster_URL = "/LovServices/GetIndustryClusterMasterLov";
export const Country_URL = "/LovServices/GetCountriesLov";
export const RegionCountry_URL="/LovServices/GetCountriesLov"

export const PriceBundle_URL = "/LovServices/GetPricePlanMasterLov";
// export const CompanyAge_URL ='/LovServices/GetCompanyAgeLov';
export const IndustryType_URL = "/LovServices/GetIndustryTypeLov";
// CREATE =>  POST: add a new master to the server
export function createmaster(master) {
  return AppAxios.post(MASTER_URL, master, { headers: Headers() });
}
export function fetchIndustryType() {
  return AppAxios.get(IndustryType_URL, { headers: Headers() });
}
export function fetchIndustryCluster() {
  return AppAxios.get(IndustryCluster_URL, { headers: Headers() });
}
export function fetchMetricName() {
  return AppAxios.get(MetricName_URL, { headers: Headers() });
}
export function fetchCountry() {
  return AppAxios.get(Country_URL, { headers: Headers() });
}
export function fetchRegionCountry() {
  return AppAxios.get(RegionCountry_URL, { headers: Headers() });
}
export function fetchCompanyAge() {
  return AppAxios.get(CompanyAge_URL, { headers: Headers() });
}
export function fetchCompanySize() {
  return AppAxios.get(CompanySize_URL, { headers: Headers() });
}
// READ
export function getAllmasters() {
  return AppAxios.get(MASTER_URL, { headers: Headers() });
}

export function getmasterById(masterId) {
  return AppAxios.get(`${MASTER_URL}/GetBenchmarkAdjFactorById`, {
    headers: Headers(masterId),
  });
}
export function fetchPriceBundle(){
  return AppAxios.get(PriceBundle_URL,{headers:Headers()});
} 
// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findmasters(queryParams) {
  if (queryParams) {
    
    return AppAxios.get(`${MASTER_URL}/GetBenchmarkAdjFactorById`, {
      headers: Headers(queryParams),
    });
  } else {
    return AppAxios.get(MASTER_URL);
  }
  //return AppAxios.post(`${MASTER_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the master on the server
export function updatemaster(master) {
  return AppAxios.put(`${MASTER_URL}`, master, { headers: Headers() });
}

// UPDATE Status
export function updateStatusFormasters(ids, status) {
  return AppAxios.post(`${MASTER_URL}/updateStatusFormasters`, {
    ids,
    status,
  });
}

// DELETE => delete the master from the server
export function deletemaster(masterId) {
  
  return AppAxios.delete(`${MASTER_URL}`, { headers: Headers(masterId) });
}

// DELETE masters by ids
export function deletemasters(ids) {
  return AppAxios.post(`${MASTER_URL}/deletemasters`, { ids });
}
