import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import * as auth from "../app/modules/Auth/config/authRedux";
import * as help from "../_metronic/layout/components/aside/aside-menu/helpRedux";
export const rootReducer = combineReducers({
  auth: auth.reducer,
  help:help.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
