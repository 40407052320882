import XLSX from "xlsx";
function Workbook() {
  if (!(this instanceof Workbook)) return new Workbook();
  this.SheetNames = [];
  this.Sheets = {};
}
const download = (url, name) => {
  let a = document.createElement("a");
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
};
function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}
/* generate an array of column objects */
export const make_cols = (refstr) => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};
export default async (sheets, fileName) => {
  import("xlsx").then(async (XLSX) => {
    console.log("in excel")
    const wb = new Workbook();
    await sheets.map(async (item) => {
      // console.log("item excel",item)
      let ws = await XLSX.utils.json_to_sheet(item.data);
      wb.SheetNames.push(item.name);
      wb.Sheets[item.name] = ws;
    });
    const wbout = XLSX.write(wb, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    });
    let url = window.URL.createObjectURL(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" })
    );
    download(url, `${fileName}.xlsx`);
  });
};
