/* eslint-disable no-restricted-imports */

import {
  Button, makeStyles, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import AppAxios, { Headers } from "../../../../src/axios";
import ExcelService from "../../../app/ExcelService";
import { fetchCompanyAge, fetchCompanySize, fetchCountry, fetchIndustryClusters, fetchIndustryType, fetchPriceBundle, fetchRegionCountry } from './masters/MasterActions';
const useStyles = makeStyles((theme) => ({
  view: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  body: {
    height: "300px",
    overflowY: "scroll",
    padding: "5px",
  },
  header: {
    backgroundColor: "#1592cb",
    color: "#ffffff",
    padding: "5px 10px",
  },
  maintablehead: {
    // backgroundColor: "#1592cb",
    color: "fff",
  },
  subtablehead: {
    backgroundColor: "fff",
  },
  flex:
  {
      display:"flex",
      flexDirection:"column"
  },
  select:
  {
    padding: "5px 10px",
    borderRadius: "9px",
    backgroundColor: "beige"
  },
  SaveBtn: {
    backgroundColor: "#1592CB",
    color: "white",
    borderRadius: "20px",
    fontWeight: "normal",
    padding: "5px 2px 2px 2px",
    paddingTop: "5px",
    "&:hover": {
        backgroundColor: "#1592CB",
        color: "white",

    },
},
}));

export default function PricePlanFilteration() {
     const [companysize, setCompanySize] = useState([]);
     const[industrytype,setIndustryType]=useState([]);
     const[regioncountry,setRegionCountry]=useState([])
     const[country,setCountry]=useState([])
     const[industrycluster,setIndustryCluster]=useState([])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const[loading,setLoading]=useState(false);
    const[companyage,setCompanyAge]=useState([])
    const handleShow = () => setShow(true);
    const[record,setRecord]=useState([])
    const[name,setName]=useState([])
    const[filterArr,setFilterArr]=useState([])
    const [filterParam, setFilterParam] = useState(["All"]);
    const[bundle,setBundle]=useState([])
    const ownershipDropdown = [
        {
            label: "All",
            value: "All",
          },
        { label: "Private", value: "Private" },
        { label: "Partnership", value: "Partnership" },
        { label: "Public", value: "Public" },
        { label: "Sole Proprietor", value: "SoleProprietor" },
      ];
      const guestMember = [
        {
            label: "All",
            value: "All",
          },
        { label: "No", value: "No" },
        { label: "Yes", value: "Yes" },
      ];
      const active = [
        {
            label: "All",
            value: "All",
          },
        { label: "false", value: "false" },
        { label: "true", value: "true" },
      ];
    const annualTurnOver = [
        {
            label: "All",
            value: "All",
          },
        { label: "US$ <5 millions", value: "US$ <5 millions" },
        { label: "US$ 5-10 millions", value: "US$ 5-10 millions" },
        { label: "US$ 10-25 millions", value: "US$ 10-25 millions" }, { label: "US$ 25-100 millions", value: "US$ 25-100 millions" },
        { label: "US$ >100 millions", value: "US$ >100 millions" },
      ];
      const holdingDropdown = [
        {
            label: "All",
            value: "All",
          },
        { label: "Holding", value: "Holding" },
        { label: "Subsidary", value: "Subsidary" },
        { label: "Not Applicable", value: "Not Applicable" },
      ];
    useEffect(() => {
      fetchPriceBundle(setBundle,setLoading)
        fetchIndustryType(setIndustryType, setLoading)
        fetchRegionCountry (setRegionCountry,setLoading)
        fetchCountry (setCountry,setLoading)
        fetchIndustryClusters(setIndustryCluster, setLoading)
        fetchCompanySize(setCompanySize, setLoading);
        fetchCompanyAge(setCompanyAge, setLoading);
        AppAxios.get(`/LovServices/GetDashBoardCustomerPricePlan`, {
          headers: Headers(),
        })
          .then((res) => {
            if (res.data.statusCode === "200") {

                setRecord(res.data.data);
                setFilterArr(res.data.data)
            }
          })
          .catch((err) => { });
      }
        , []);
        const Filter=()=>
  {
      if(filterParam!="All")
    { var object1=filterArr.map((val)=>Object.keys(val))[0].filter((item)=>item==name)
      const display= filterArr.filter((val)=>val[name]==filterParam)
   setRecord(display)
   console.log("display",display,"onj",object1)}
   else
   {
       setRecord(filterArr)
   }
        }
    const PersonaLov = [
        {
            label: "All",
            value: "All",
          },
        {
          label: "Business Owner or Manager",
          value: "Business Owner or Manager",
        },
        {
          label: "Investor or Stakeholder",
          value: "Investor or Stakeholder",
        },
        {
          label: "Consultant/ Advisor",
          value: "Consultant/ Advisor",
        },
        {
          label: "Student",
          value: "Student",
        },
      
      ];
  const classes = useStyles();

  const handleExport = () => {
    let sheets = [
      {
        data:
          record.length > 0
            ? record.map(({ company, size, age, benchMark_Region, country, email, pricePlan, discount, invoiceValue }) => ({
                "Company Name": company,
                "Size": size,
                "Age": age,
                "Region": benchMark_Region,
                "Country": country,
                "Email": email,
                // "Contact Person": '',
                // "Date Purchased": '',
                "Bundle Name": pricePlan,
                // "Refund": '',
                "Discount": discount,
                "Invoice": invoiceValue,
              }))
            : [{
              "Company Name": '',
              "Size": '',
              "Age": '',
              "Region": '',
              "Country": '',
              "Email": '',
              // "Contact Person": '',
              // "Date Purchased": '',
              "Bundle Name": '',
              // "Refund": '',
              "Discount": '',
              "Invoice": '',
            }],
        name: "Subscription of Price Plan",
      },
    ];
    ExcelService(sheets, "Subscription of Price Plan");
  };

  return (
    <>
        <Card style={{ borderRadius: "12px" }}>
          <Card.Header className={classes.header}>
            <div className={([classes.view], "d-flex justify-content-between")}>
              <p
                className={"mt-3 pt-1"}
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  padding: "none",
                  margin: "none",
                  width: "100%",
                }}
              >
           <Button variant="primary" onClick={handleShow}>
           Single Filter
          </Button>
          {record.length > 0 ?
          <button
              type="button"
              className="btn btn-light-primary font-weight-bolder font-size-sm"
              style={{
                border: "1px solid #FFF",
                float: "right",
              }}
              onClick={handleExport}
            >
              <i class="far fa-file-excel"></i> Export
          </button>
          : null}
          <h5> Subscription of Price Plan</h5>
              </p>
              {/* <img
                className="mt-2"
                src={src}
                width="30px"
                height="30px"
                alt="icon"
              /> */}
            </div>
          </Card.Header>
          <Card.Body className={classes.body}>
          <TableContainer style={{"overflowX": "initial!important"}}>
      <Table
        striped
        hover
        responsive
        className={classes.maintable}
        aria-label="simple table"
      >
        <TableHead className={classes.maintablehead}>
          <TableRow>
            {/* <TableCell></TableCell> */}
            <TableCell
              style={{
                color: "#1592cb",
                fontSize: "12px",
                fontWeight: "700",
              }}
              align="center"
            >
           Company Name
            </TableCell>
            <TableCell
              style={{
                color: "#1592cb",
                fontSize: "12px",
                fontWeight: "700",
              }}
              align="center"
            >
             Size
            </TableCell>
            <TableCell
              style={{
                color: "#1592cb",
                fontSize: "12px",
                fontWeight: "700",
              }}
              align="center"
            >
              Age
            </TableCell>
            <TableCell
              style={{
                color: "#1592cb",
                fontSize: "12px",
                fontWeight: "700",
              }}
              align="center"
            >
            Region
            </TableCell>
            <TableCell
              style={{
                color: "#1592cb",
                fontSize: "12px",
                fontWeight: "700",
              }}
              align="center"
            >
            Country
            </TableCell>
            <TableCell
              style={{
                color: "#1592cb",
                fontSize: "12px",
                fontWeight: "700",
              }}
              align="center"
            >
            Email
            </TableCell>
            <TableCell
              style={{
                color: "#1592cb",
                fontSize: "12px",
                fontWeight: "700",
              }}
              align="center"
            >
             Contact Person
            </TableCell>
            <TableCell
              style={{
                color: "#1592cb",
                fontSize: "12px",
                fontWeight: "700",
              }}
              align="center"
            >
            Date Purchased
            </TableCell>
            <TableCell
              style={{
                color: "#1592cb",
                fontSize: "12px",
                fontWeight: "700",
              }}
              align="center"
            >
            Bundle Name
            </TableCell>
            <TableCell
              style={{
                color: "#1592cb",
                fontSize: "12px",
                fontWeight: "700",
              }}
              align="center"
            >
            Refund
            </TableCell>
            <TableCell
              style={{
                color: "#1592cb",
                fontSize: "12px",
                fontWeight: "700",
              }}
              align="center"
            >
            Discount
            </TableCell>
            <TableCell
              style={{
                color: "#1592cb",
                fontSize: "12px",
                fontWeight: "700",
              }}
              align="center"
            >
            Invoice
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.lasttablebody}>
          {record?.map((row) => (
            <>
            <TableRow >
               <TableCell align="center" component="th" scope="row">
             {row.company}
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                {row.size}
              </TableCell>
              <TableCell align="center">
                {row.age}
              </TableCell>
              <TableCell align="center">
              {row.benchMark_Region}
              </TableCell>
              <TableCell align="center">
              {row.country}
              </TableCell>
              <TableCell align="center">
              {row.email}
              </TableCell>
              <TableCell align="center">
              </TableCell>
              <TableCell align="center">
                
              </TableCell>
              <TableCell align="center">
                {row.pricePlan}
              </TableCell>
              <TableCell align="center">
               
              </TableCell>
              <TableCell align="center">
                {row.discount}
              </TableCell>
              <TableCell align="center">
                {row.invoiceValue}
              </TableCell>
            </TableRow>
          </>
         ))}
        </TableBody>
      </Table>
    </TableContainer>
          </Card.Body>
        </Card>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Single Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className={classes.flex} style={{"marginBottom":"10px"}}>
            <div className={classes.flex}>
         <label>Persona</label>
        <select
        className={classes.select}
        name="persona"
        onChange={(e) => {
            setFilterParam(e.target.value);
            setName(e.target.name)
              var list = document.getElementsByTagName("SELECT")

            for(var i=0; i< list.length; i++)
        {    
           if(list[i].name !=e.target.name) 
           {
           
            console.log("ee",e.target.name,e.target.value)
            list[i].value = "All";
           }
        } 
           
        }}
        >
        {PersonaLov.map((val,y) => <option key={y} value={val.label}>{val.label}</option>)}
        </select>
        </div>
        <div className={classes.flex}>
         <label>Annual Turnover</label>
        <select
        className={classes.select}
        name="annual_Turnover"
        onChange={(e) => {
            setFilterParam(e.target.value);
            setName(e.target.name)
              var list = document.getElementsByTagName("SELECT")

            for(var i=0; i< list.length; i++)
        {    
           if(list[i].name !=e.target.name) 
           {
           
            console.log("ee",e.target.name,e.target.value)
            list[i].value = "All";
           }
        } 
           
        }}
        >
        {annualTurnOver.map((val,y) => <option key={y} value={val.label}>{val.label}</option>)}
        </select>
        </div>
        <div className={classes.flex} >
         <label>Ownership Type</label>
        <select
        className={classes.select}
        name="ownership"
        onChange={(e) => {
            setFilterParam(e.target.value);
            setName(e.target.name)
              var list = document.getElementsByTagName("SELECT")

            for(var i=0; i< list.length; i++)
        {    
           if(list[i].name !=e.target.name) 
           {
           
            console.log("ee",e.target.name,e.target.value)
            list[i].value = "All";
           }
        } 
           
        }}
        >
        {ownershipDropdown.map((val,y) => <option key={y} value={val.label}>{val.label}</option>)}
        </select>
        </div>
        <div className={classes.flex}>
         <label>Holding</label>
        <select
        className={classes.select}
        name="holding"
        onChange={(e) => {
            setFilterParam(e.target.value);
            setName(e.target.name)
              var list = document.getElementsByTagName("SELECT")

            for(var i=0; i< list.length; i++)
        {    
           if(list[i].name !=e.target.name) 
           {
           
            console.log("ee",e.target.name,e.target.value)
            list[i].value = "All";
           }
        } 
           
        }}
        >
        {holdingDropdown.map((val,y) => <option key={y} value={val.label}>{val.label}</option>)}
        </select>
        </div>
        <div className={classes.flex}>
         <label>Company Age</label>
        <select
        className={classes.select}
        name="age"
        onChange={(e) => {
            setFilterParam(e.target.value);
            setName(e.target.name)
              var list = document.getElementsByTagName("SELECT")

            for(var i=0; i< list.length; i++)
        {    
           if(list[i].name !=e.target.name) 
           {
           
            console.log("ee",e.target.name,e.target.value)
            list[i].value = "All";
           }
        } 
           
        }}
        >
        {companyage.map((val,y) => <option key={y} value={val.label}>{val.label}</option>)}
        </select>
        </div>
        <div className={classes.flex}>
         <label>Company Size</label>
        <select
        className={classes.select}
        name="size"
        onChange={(e) => {
            setFilterParam(e.target.value);
            setName(e.target.name)
              var list = document.getElementsByTagName("SELECT")

            for(var i=0; i< list.length; i++)
        {    
           if(list[i].name !=e.target.name) 
           {
           
            console.log("ee",e.target.name,e.target.value)
            list[i].value = "All";
           }
        } 
           
        }}
        >
        {companysize.map((val,y) => <option key={y} value={val.label}>{val.label}</option>)}
        </select>
        </div>
        <div className={classes.flex}>
         <label>Guest</label>
        <select
        className={classes.select}
        name="guest"
        onChange={(e) => {
            setFilterParam(e.target.value);
            setName(e.target.name)
              var list = document.getElementsByTagName("SELECT")

            for(var i=0; i< list.length; i++)
        {    
           if(list[i].name !=e.target.name) 
           {
           
            console.log("ee",e.target.name,e.target.value)
            list[i].value = "All";
           }
        } 
           
        }}
        >
        {guestMember.map((val,y) => <option key={y} value={val.label}>{val.label}</option>)}
        </select>
        </div>
        <div className={classes.flex}>
         <label>Member</label>
        <select
        className={classes.select}
        name="member"
        onChange={(e) => {
            setFilterParam(e.target.value);
            setName(e.target.name)
              var list = document.getElementsByTagName("SELECT")

            for(var i=0; i< list.length; i++)
        {    
           if(list[i].name !=e.target.name) 
           {
           
            console.log("ee",e.target.name,e.target.value)
            list[i].value = "All";
           }
        } 
           
        }}
        >
        {guestMember.map((val,y) => <option key={y} value={val.label}>{val.label}</option>)}
        </select>
        </div>
        <div className={classes.flex}>
         <label>Industry Type</label>
        <select
        className={classes.select}
        name="industry_Type"
        onChange={(e) => {
            setFilterParam(e.target.value);
            setName(e.target.name)
              var list = document.getElementsByTagName("SELECT")

            for(var i=0; i< list.length; i++)
        {    
           if(list[i].name !=e.target.name) 
           {
           
            console.log("ee",e.target.name,e.target.value)
            list[i].value = "All";
           }
        } 
           
        }}
        >
        {industrytype.map((val,y) => <option key={y} value={val.label}>{val.label}</option>)}
        </select>
        </div>
        <div className={classes.flex}>
         <label>Industry Cluster</label>
        <select
        className={classes.select}
        name="industry_Cluster"
        onChange={(e) => {
            setFilterParam(e.target.value);
            setName(e.target.name)
              var list = document.getElementsByTagName("SELECT")

            for(var i=0; i< list.length; i++)
        {    
           if(list[i].name !=e.target.name) 
           {
           
            console.log("ee",e.target.name,e.target.value)
            list[i].value = "All";
           }
        } 
           
        }}
        >
        {industrycluster.map((val,y) => <option key={y} value={val.label}>{val.label}</option>)}
        </select>
        </div>
        <div className={classes.flex}>
         <label>Country Region</label>
        <select
        className={classes.select}
        name="benchMark_Region"
        onChange={(e) => {
            setFilterParam(e.target.value);
            setName(e.target.name)
              var list = document.getElementsByTagName("SELECT")

            for(var i=0; i< list.length; i++)
        {    
           if(list[i].name !=e.target.name) 
           {
           
            console.log("ee",e.target.name,e.target.value)
            list[i].value = "All";
           }
        } 
           
        }}
        >
        {regioncountry.map((val,y) => <option key={y} value={val.label}>{val.label}</option>)}
        </select>
        </div>
        <div className={classes.flex}>
         <label>Country</label>
        <select
        className={classes.select}
        name="country"
        onChange={(e) => {
            setFilterParam(e.target.value);
            setName(e.target.name)
              var list = document.getElementsByTagName("SELECT")

            for(var i=0; i< list.length; i++)
        {    
           if(list[i].name !=e.target.name) 
           {
           
            console.log("ee",e.target.name,e.target.value)
            list[i].value = "All";
           }
        } 
           
        }}
        >
        {country.map((val,y) => <option key={y} value={val.label}>{val.label}</option>)}
        </select>
        </div>
        <div className={classes.flex}>
         <label>Active</label>
        <select
        className={classes.select}
        name="active"
        onChange={(e) => {
            setFilterParam(e.target.value);
            setName(e.target.name)
              var list = document.getElementsByTagName("SELECT")

            for(var i=0; i< list.length; i++)
        {    
           if(list[i].name !=e.target.name) 
           {
           
            console.log("ee",e.target.name,e.target.value)
            list[i].value = "All";
           }
        } 
           
        }}
        >
        {guestMember.map((val,y) => <option key={y} value={val.label}>{val.label}</option>)}
        </select>
        </div>
        <div className={classes.flex}>
         <label>Price Plan Bundle</label>
        <select
        className={classes.select}
        name="pricePlan"
        onChange={(e) => {
            setFilterParam(e.target.value);
            setName(e.target.name)
              var list = document.getElementsByTagName("SELECT")

            for(var i=0; i< list.length; i++)
        {    
           if(list[i].name !=e.target.name) 
           {
           
            console.log("ee",e.target.name,e.target.value)
            list[i].value = "All";
           }
        } 
           
        }}
        >
        {bundle.map((val,y) => <option key={y} value={val.label}>{val.label}</option>)}
        </select>
        
        </div>
        </div>
      
        <Button variant="contained" onClick={Filter} className={classes.SaveBtn}>
            Filter
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    
      
    </>
  );
}
