import { createMuiTheme, IconButton, ThemeProvider } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import SVG from "react-inlinesvg";
import { useRoutesContext } from "../../../../app/RoutesContext";
import { toAbsoluteUrl } from "../../../_helpers";
import FAQs from "../content/FAQs";
import Help from "../content/Help";
import { TopHeaderComponent } from "./TopHeaderComponent";
const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "30%",
    marginTop: theme.spacing(9),
  },
  description: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fffff",
    borderBottom: "1px solid #f3f3f3",
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fffff",
    borderBottom: "1px solid #f3f3f3",
  },
  container: {
    width: "80%",
    border: "solid",
    borderRadius: "7px",
    marginTop: "10px",
    borderWidth: "thin",
    display: "flex",
  }
}));
const theme = createMuiTheme({
  ".MuiPaper-elevation1": {
    boxShadow: "0",
  },
});
export default function PageHelpTipAside(props) {
  const classes = useStyles();
  const RoutesContext = useRoutesContext();
  const { open, setOpen, menuData, title } = props;
  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor="right"
          //className={}
          classes={{
            paper: classes.drawerPaper,
          }}
          open={RoutesContext.helpAside || open}
        >
          <div className={`${classes.header} p-2`}>
            <div className="d-flex flex-row">
              <span className="svg-icon menu-icon">
                {
                  title === "Page Help" ?
                  <img alt="img" src="https://img.icons8.com/office/16/000000/help.png"/>
                    : <SVG src={toAbsoluteUrl("/media/svg/shapes/bulb.svg")} />
                }
              </span>
              <span class="font-medium align-middle ml-2">{RoutesContext.helpAside?'Help and Support':title}</span>
            </div>
            <IconButton
              color="primary"
              component="span"
              onClick={() => {
                RoutesContext.setHelpAside(null);
                setOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          {RoutesContext.helpAside ? (
            <>
              <TopHeaderComponent {...props} />
            </>
          ) : (
            <div>
              <div>
                <h5 class="text-dark font-weight-bold my-2 ml-2 mr-2">
                  {menuData?.name}
                </h5>
                <div className={`${classes.description} p-2`}>
                  <div class="comment-date my-2 ml-5 mr-5 font-small">
                    {menuData?.description}
                  </div>{" "}
                </div>
              </div>
              
              <ThemeProvider theme={theme}>
              {title === "Page Help" ? (
                <Help menuData={menuData} classes={classes} />
              ) : (
                <FAQs menuData={menuData} classes={classes} />
              )}
              </ThemeProvider>
            </div>
          )}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
