import { useField } from "formik";
import React from "react";
import Select2 from "react-select";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid-select");
  }

  return classes.join(" ");
};
const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.name}</span>
  </div>
);
export function Select({
  label,
  data,
  setOption,
  withFeedbackLabel = true,
  type = "text",
  customFeedbackLabel,
  children,
  ...props
}) {
  const [field, meta] = useField(props);
  const { touched, error } = meta;
  return (
    <>
      {label && <label>{label}</label>}
      <select
        className={getFieldCSSClasses(touched, error)}
        {...field}
        {...props}
      >
        {children}
      </select>

      {withFeedbackLabel && (
        <FieldFeedbackLabel
          erros={error}
          touched={touched}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
export function SelectSearch({
  label,
  data,
  value,
  setOption,
  Error,
  setError,
  withFeedbackLabel = true,
  type = "text",
  customFeedbackLabel,
  checkForNull,
  children,
  ...props
}) {
  const [field, meta] = useField(props);
  console.log("innnn",label,value,data)
  const handleChange = (selectedOption) => {
    setError(false);
    setOption(selectedOption);
  };
  const { touched, error } = meta;
  const isValue =
    value && Object.keys(value).every((k) => value[k] !== undefined)
      ? true
      : false;
  return (
    <>
      {label && <label className="text-sm-left">{label}</label>}

      <Select2
        //className={getFieldCSSClasses(touched, error)}
        //{...field}
        value={value}
        {...props}
        autoComplete="off"
        // menuPortalTarget={document.body}
        menuPosition={"fixed"}
        options={data}
        onChange={handleChange}
        formatGroupLabel={formatGroupLabel}
      />
      {touched && !isValue && !checkForNull && (
        <div
          className="feedback"
          style={{ fontSize: "12px", padding: "2px", color: "red" }}
        >
          {label && <>{label.split("*")[0]} is Required</>}
        </div>
      )}
      {touched&& checkForNull && !value.hasOwnProperty("label") && (
        <div
          className="feedback"
          style={{ fontSize: "12px", padding: "2px", color: "red" }}
        >
          {label && <>{label.split("*")[0]} is Required</>}
        </div>
      )}
      {!Error && withFeedbackLabel && (
        <FieldFeedbackLabel
          erros={error}
          touched={touched}
          label={label}
          isValue={isValue}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}

export function GroupedSelect({
  label,
  data,
  value,
  setOption,
  Error,
  setError,
  withFeedbackLabel = true,
  type = "text",
  customFeedbackLabel,
  children,
  groups,
  ...props
}) {
  const [field, meta] = useField(props);
  const handleChange = (selectedOption) => {
    setError(false);
    setOption(data.filter((d) => d.pD_Id === selectedOption)[0]);
  };
  const { touched, error } = meta;
  return (
    <>
      {label && <label>{label}</label>}
      <select
        onChange={(e) => handleChange(e.target.value)}
        className={getFieldCSSClasses(touched, error)}
      >
        <optgroup label="">
          <option value=""></option>
        </optgroup>
        {groups.map((d) => (
          <optgroup label={d}>
            {data
              .filter((i) => i.type === d)
              .map((e) => (
                <option value={e.pD_Id}>{e.pD_Name}</option>
              ))}
          </optgroup>
        ))}
      </select>
      {Error && (
        <div
          className="feedback"
          style={{ fontSize: "12px", padding: "2px", color: "red" }}
        >
          {label && <>{label} is Required</>}
        </div>
      )}
      {!Error && withFeedbackLabel && (
        <FieldFeedbackLabel
          erros={error}
          touched={touched}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
