import { IconButton, makeStyles, Typography } from "@material-ui/core";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import React from "react";
import { useHistory } from "react-router";
import { useRoutesContext } from "../../../../app/RoutesContext";
import { toAbsoluteUrl } from "../../../_helpers";

const useStyles = makeStyles((theme) => ({
  buttondiv: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  button: {
    height: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "120px",
    justifyContent: "space-around",
    margin: "5px",
    "&:hover": {
      backgroundColor: "#fff",
      boxShadow: "3px 4px 4px #d4cecebf",
      border: "1px solid #d4cecebf",
      transition: "0.3s",
      borderRadius: "25px",
    },
  },
  icon: {
    width: "50px",
    height: "50px",
  },
  toll: {
    width: "50px",
    height: "50px",
  },
  vidicon: {
    width: "100%",
    height: "100%",
  },
  liicon: {
    width: "35px",
    height: "35px",
    margin: "0px 10px",
  },
  assistance: {
    width: "90%",
    margin: "auto",
  },
  b: {
    fontSize: "medium",
  },
  li: {
    margin: "10px 0px",
  },
  tolldiv: {
    width: "90%",
    margin: "auto",
    display: "flex",
  },
  iconbtn: {
    color: "#fff",
    width: "50px",
    height: "50px",
  },
  th: {
    padding: theme.spacing(2),
  },
}));

export const TopHeaderComponent = (props) => {
  const history = useHistory();
  const RoutesContext = useRoutesContext();
  const classes = useStyles();
  return (
    <div className="row" className={classes.th}>
      <div className={classes.buttondiv}>
        <div
          className={classes.button}
          onClick={() => {
            RoutesContext.setPage("help");
            history.push("/helpandsupport/main");
          }}
        >
          <img
            alt="img"
            src={toAbsoluteUrl("/media/svg/helpandsupport/search.svg")}
            className={classes.icon}
          />
          <Typography style={{ textAlign: "center" }}>
            Help Documents
          </Typography>
        </div>
        <div
          className={classes.button}
          onClick={() => {
            RoutesContext.setPage("faq");
            history.push("/helpandsupport/main");
          }}
        >
          <img
            alt="img"
            src={toAbsoluteUrl("/media/svg/helpandsupport/conversation.svg")}
            className={classes.icon}
          />
          <Typography>FAQ</Typography>
        </div>
        <div
          className={classes.button}
          onClick={() => {
            RoutesContext.setPage("video");
            history.push("/helpandsupport/main");
          }}
        >
          <img
            alt="img"
            src={toAbsoluteUrl("/media/svg/helpandsupport/internet.svg")}
            className={classes.icon}
          />
          <Typography>Product Tour</Typography>
        </div>
      </div>
      <div className={classes.assistance}>
        <h3 className="m-2 d-flex">
          <b>Need Assistance?</b>
          <hr className="w-60" />
        </h3>
        <div>
          <ul style={{ listStyleType: "none", margin: "0px" }}>
            <li className={classes.li}>
              <img
                alt="img"
                className={classes.liicon}
                src={toAbsoluteUrl("/media/svg/helpandsupport/chat.svg")}
              />
              <b className={classes.b}>Chat with our Experts</b>
            </li>
            <li className={classes.li}>
              <img
                alt="img"
                className={classes.liicon}
                src={toAbsoluteUrl("/media/svg/helpandsupport/send.svg")}
              />
              <b className={classes.b}>Send email</b>
            </li>
          </ul>
        </div>
      </div>
      <div className={classes.assistance}>
        <h3 className="m-2 d-flex">
          <b>Video Tutorial</b>
          <hr className="w-60" />
        </h3>
        <div className={classes.buttondiv}>
          <div
            className={classes.button}
            style={{
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/svg/helpandsupport/img1.png"
              )})`,
              backgroundSize: "100% 100%",
            }}
          >
            <IconButton className={classes.iconbtn}>
              <PlayCircleOutlineIcon />
            </IconButton>
          </div>
          <div
            className={classes.button}
            style={{
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/svg/helpandsupport/img2.jpg"
              )})`,
              backgroundSize: "100% 100%",
            }}
          >
            <IconButton className={classes.iconbtn}>
              <PlayCircleOutlineIcon />
            </IconButton>
          </div>
          <div
            className={classes.button}
            style={{
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/svg/helpandsupport/img3.png"
              )})`,
              backgroundSize: "100% 100%",
            }}
          >
            <IconButton className={classes.iconbtn}>
              <PlayCircleOutlineIcon />
            </IconButton>
          </div>
        </div>
        <a href="#" className="m-2">View More Videos</a>
      </div>
      <hr className="w-100" />
      <div className={classes.tolldiv}>
        <div className="col-3">
          <img
            alt="img"
            className={classes.toll}
            src={toAbsoluteUrl("/media/svg/helpandsupport/toll.png")}
          />
        </div>
        <div className="col-9">
          <h6>
            <b>Toll Free Helpline-080004442</b>
          </h6>
          <small>sat - thu 9:00 AM to 6:00 PM</small>
        </div>
      </div>
    </div>
  );
};
