/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useRoutesContext } from "../../../../../app/RoutesContext";
import AppAxios, { Headers } from "../../../../../axios";
import { checkIsActive } from "../../../../_helpers";
import * as help from "./helpRedux";

function HelpAsideMenuList(props) {
  const { layoutProps } = props;
  const [menuCategory, setMenuCategory] = useState([]);
  const RoutesContext = useRoutesContext();
  const userMenuPermissions = useSelector(
    (state) => state?.auth?.user?.userMenuPermissions
  );

  useEffect(() => {
    AppAxios.get("/LovServices/GetHelCategSubCategpLov", {
      headers: Headers(),
    })
      .then((response) => {
        if (response.data.statusCode === "200") {
          let descriptions = [];
          setMenuCategory(response.data.data);
          let d = response.data.data
          for(let i=0;i<d.length;i++){
            for(let j=0;j<d[i].lovHSCasync.length;j++){
              descriptions.push(d[i].lovHSCasync[j])
           }
           }
          props.requestDescription(descriptions);
        } else {
          setMenuCategory(null);
        }
      })
      .catch((error) => {});
  }, []);

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  return (
    <>
      {/* begin::Menu Nav */}
      <ul
        className={`menu-nav data-auto-scroll="true" ${layoutProps.ulClasses}`}
      >
        {menuCategory && menuCategory.length > 0
          ? menuCategory.map(
              (item) =>
                userMenuPermissions.find((itm) => itm.id === item.menu_Id)
                  ?.viewPermission === true && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/google-material",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/google-material"
                    >
                      {/* <span className="svg-icon menu-icon">
                      {IconToRender[item.name]}
                      </span> */}
                      <span className="menu-text">{item.hC_Name}</span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className="menu-submenu ">
                      <i className="menu-arrow" />
                      <ul className="menu-subnav">
                        {item.lovHSCasync.map((itm) => (
                          <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive(
                              `${itm.hsC_Id}`,
                              true
                            )}`}
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                          >
                            <NavLink
                              className="menu-link menu-toggle"
                              to={`/helpandsupport/${RoutesContext.page}/${itm.hsC_Id}`}
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">{itm.hsC_Name}</span>
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                )
            )
          : null}
      </ul>
    </>
  );
}
export default connect(null, help.actions)(HelpAsideMenuList);
