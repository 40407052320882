import AppAxios, { Headers } from "../../../../axios";

export const LOGIN_URL = "/Auth/Login";
export const ResetPassword_URL = "/Auth/ResetPassword";
export const REQUEST_PASSWORD_URL = "/Auth/ForgetPassword";

export const ME_URL = "/auth/me";

export function login(email, password) {
  return AppAxios.post(LOGIN_URL, { email, password });
}

export function changepassword(state) {
  return AppAxios.post(ResetPassword_URL, state,{headers:Headers()});
}

export function requestPassword(email) {
  return AppAxios.post(REQUEST_PASSWORD_URL+`?email=${email}`,{headers:Headers()});
}

